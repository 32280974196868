<template>
  <article
    class="blog-tile"
  >
    <component
      :is="getElement"
      v-if="blog"
      class="blog-tile__link"
      :to="`/${blog?.PageUrl?.url}`"
    >
      <div class="blog-tile__visual">
        <picture v-if="blog?.Image?.url">
          <source
            :srcset="useProductImage(blog.Image.url, 'f=rs:fit:500')"
            media="(min-width: 1024px)"
          >
          <source
            :srcset="useProductImage(blog.Image.url, 'f=rs:fit:750')"
            media="(min-width: 768px)"
          >
          <source
            :srcset="useProductImage(blog.Image.url, 'f=rs:fit:500')"
          >
          <img
            v-if="blog?.Image?.url"
            class="blog-tile__image"
            :src="useProductImage(blog.Image.url, 'f=rs:fit:500')"
            :alt="blog.Image.alternativeText || blog.PageTitle"
            :width="blog.Image.width"
            :height="blog.Image.height"
            :loading="lazy ? 'lazy' : 'eager'"
            :fetchpriority="lazy ? 'low' : 'high'"
            :decoding="lazy ? 'async' : 'sync'"
          >
        </picture>
      </div>

      <div class="blog-tile__content">
        <div
          class="blog-tile__heading heading heading--6"
        >
          {{ blog?.PageTitle }}
        </div>

        <p
          class="blog-tile__description"
        >
          {{ blog?.Description }}
        </p>

        <div class="blog-tile__info">
          <img
            v-if="blog?.Author?.Image?.url"
            :src="useProductImage(blog.Author.Image.url, 'f=rs:fit:40')"
            :alt="blog.Author.Image.alternativeText || blog.Author.Name"
            :width="blog.Author.Image.width"
            :height="blog.Author.Image.height"
            :loading="lazy ? 'lazy' : 'eager'"
            :fetchpriority="lazy ? 'low' : 'high'"
            :decoding="lazy ? 'async' : 'sync'"
            class="blog-tile__author-image"
          >
          <span
            v-if="blog?.Author?.Name"
            class="blog-tile__author"
          >
            {{ blog?.Author?.Name }} in {{ blog?.ParentPage?.PageTitle }}
          </span>

          <span
            v-if="blog?.PublicationDate"
            class="blog-tile__date"
          >
            {{ $d(new Date(blog.PublicationDate), 'long') }}
          </span>

          <span
            v-if="blog?.ReadingTime"
            class="blog-tile__reading-time"
          >
            -
            <IconClock class="icon icon--clock" />
            {{ blog?.ReadingTime }} minuten
          </span>
        </div>
      </div>
    </component>
  </article>
</template>

<script setup lang="ts">
import { AppLink } from '#components'

const props = defineProps<{
  blog: any
  lazy: boolean
}>()

const getElement = computed(() => {
  if (props?.blog?.PageUrl?.url) {
    return AppLink
  }

  return 'div'
})
</script>

<style src="~layers/app/components/Blog/Tile.css" />
